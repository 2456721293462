var zh = {
    "languageArr": ["中", "EN", "JPN"],
    "login": "登录",
    "search": {
        "title": "搜索",
        "cateArr": ["全部", "应用市场", "产品中心", "新闻中心"],
        "placeholder": "请输入关键词搜索",
        "record": "历史记录"
    },
    "product_search": {
        "placeholder": "请输入产品名称, 产品类别, 用途等。",
        "btn": "搜索"
    },
    "statistics_arr": ["技术专长", "专利数量", "研发面积", "研发人员", "科研院校"],
    "unit_arr": ["项", "项", "㎡", "+", "所"],
    "market_language": {
        "title": "应用市场",
        "desc": "专注于高端无机非金属粉体材料应用及解决方案",
        "market": ["电子信息材料", "新能源材料", "半导体材料", "其他行业"],
        "btn": "了解更多"
    },
    "product_language": {
        "title": "产品中心",
        "desc": "以客户为中心, 坚持创新驱动",
        "product": [
            { "name": "硅系", "desc": "硅微粉、球硅、球硅浆料" }, 
            { "name": "铝系", "desc": "角铝、球铝" }, 
            { "name": "氮系", "desc": "氮化铝、氮化硼" },
            { "name": "钛系", "desc": "单晶氧化钛" },
            { "name": "陶瓷系列", "desc": "钛酸钡、透锂长石" },
            { "name": "电子浆料", "desc": "烧结铜浆、固化铜浆" }
        ]
    },
    "group_introduction": {
        "title": "公司介绍",
        "inroduction": {
            "para1": "苏州锦艺新材料科技股份有限公司于2005年创立，致力于提供高端无机非金属粉体新材料应用解决方案，是一家集研发、生产、销售、技术服务为一体的国家级高新技术企业。公司聚焦客户关注的压力和挑战，挖掘无机非金属材料的潜能，通过独有的核心技术和核心优势，融合各种有机材料，提供优异功能和低成本粉体解决方案，大力拓展在新能源、新材料、环保节能等快速发展领域的应用。",
            "para2": "公司总部坐落在苏州，地处优异的地理位置，联合南京航空航天大学、西南科技大学等国内知名院校，吸纳各个学科的专业技术人才，打造无机非金属粉体材料行业独树一帜的研发平台。",
            "para3": "公司与国内各个无机非金属材料优秀企业、优势资源、优良储备合作，共建生态链平台，服务于各大跨国企业、上市企业及优秀国内中小型企业，不断推出高性价比的产品，创造客户价值，实现资本增值。"
        },
    },
    "core_advantage": {
        "title": "核心优势",
        "desc": "创造客户价值, 实现资本增值",
        "core_arr": [
            { "name": "多样技术专长", "desc": "复合配方、超细研磨<br />精密分级、精细球化<br />表面处理、定制浆料<br />" },
            { "name": "多种无机材料", "desc": "立足无机、携手有机<br />融合创新、更新迭代<br />" },
            { "name": "精益生产平台", "desc": "柔性生产、快速交付<br />品质提升、注重环保<br />" },
            { "name": "严格品质管控", "desc": "完善质量体系<br />先进检测仪器<br />优良生产设备<br />权威检测方法<br />" },
            { "name": "定制解决方案", "desc": "客户需求、共同研发<br />共同实验、行业优势<br />" },
            { "name": "开放合作平台", "desc": "外资企业，国有企业<br />科研院校，创业团队<br />" }
        ]
    },
    "our_growth": {
        "title": "我们的成长"
    },
    "company_culture": {
        "title": "企业文化",
        "culture": [
            { "title": "愿景", "desc": "高端无机非金属材料应用及解决方案供应商" },
            { "title": "使命", "desc": "聚焦客户关注的压力和挑战，挖掘无机非金属材料的潜能" },
            { "title": "价值观", "desc": "以客户为中心，坚持创新驱动；以奋斗者为本，践行工匠精神" }
        ]
    },
    "talent_concept": {
        "title": "人才观",
        "concept": [
            { "title": "专业", "desc": "持续学习，知行合一<br />坚持人尽其才，践行工匠精神" },
            { "title": "卓越", "desc": "锐意进取，精益求精<br />追求极致表现，成就非凡价值" },
            { "title": "创新", "desc": "开放包容，拥抱变化<br />坚定持续改善，勇于革故鼎新" }
        ]
    },
    "school": {
        "title": "校企合作"
    },
    "development": {
        "title": "职业发展",
        "desc1": "公司提供管理、专业双通道职业发展路径。",
        "desc2": "其中专业通道包括营销、技术、职能、操作4个序列"
    },
    "salary": {
        "title": "薪酬福利",
        "salary_arr": [
            { "title": "基础薪资", "desc": "基础薪资、绩效、全勤奖、津贴、补贴" },
            { "title": "社会保险", "desc": "公司为所有员工购买五险一金" },
            { "title": "生活服务", "desc": "提供住宿、班车、自助餐厅、运动健身场地" },
            { "title": "激励机制", "desc": "年终奖、项目突破奖、股权、期权激励等" },
            { "title": "员工发展", "desc": "多样化的培训课程和职业发展规划" }
        ],
        "btn": "加入我们"
    },
    "subsidiary": {
        "btn": "了解详情"
    },
    "honor": {
        "title": "荣誉资质"
    },
    "wechat_code": "官方微信号",
    "contact": {
        "contact_us": "联系我们",
        "phone": "电话",
        "mail": "邮箱",
        "address": "地址",
        "address_detail": "江苏省常熟市经济技术开发区兴港路25-1",
        "copyright": "Copyright©️ 2024 苏州锦艺新材料科技股份有限公司",
        "download": "资料下载",
        "summary": "概要",
        "feature": "特点",
        "function": "用途"
    },
    "fill_information": {
        "title": "申请资料信息填写",
        "desc": "填写资料后，自动下载相关产品文件",
        "not_null": "必填",
        "name": "姓名",
        "unit": "单位名称",
        "department": "部门",
        "mail": "E-mail",
        "confirm": "确认",
        "phone": "电话",
        "query": "查询产品",
        "select": "请选择",
        "content": "具体内容",
        "submit": "确认"
    },
    "read_more": {
        "btn": "了解更多"
    }
}

export default zh